<template>
  <div class="pa-5">
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'Accounts.AccountsManagement'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click="$router.push('Accounts/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('Accounts.AddAccount') }}
        </v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th v-t="'Accounts.Username'" class='text-center'></th>
            <th v-t="'Accounts.Roles'" class='text-center'></th>
            <th v-t="'General.Actions'" class='text-center'></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='account in accounts' :key='account.id'>
            <td>{{ account.username }}</td>
            <td><p v-for="role in account.roles" :key="role" style="margin-top: 10px;">{{ role }}</p></td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Accounts/' + account.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Edit'"></span>
              </v-tooltip>
              <v-tooltip bottom v-if="!account.roles.find((role) => { return role === 'ROLE_ADMIN' })">
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='deleteAccount(account)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Delete'"></span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Accounts',
  data () {
    return {
      accounts: [],
      product: []
    }
  },
  created () {
    this.getAccounts()
  },
  methods: {
    deleteAccount (account) {
      this.$store.dispatch('deleteAccount', account.id).then(() => {
        this.accounts = this.$store.getters['GET_ACCOUNTS']()
        this.$emit('notify', {
          color: 'green',
          text: this.$t('Messages.AccountDeleted')
        })
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    },
    getAccounts () {
      this.$store.dispatch('getAllAccounts').then(() => {
        this.accounts = this.$store.getters['GET_ACCOUNTS']()
      })
    },
    modifyAccount (account) {
      this.$store.dispatch('editAccount', account).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
